<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field type="text" filled label="Код" v-model="code"
                          hide-details
                          disabled
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12" v-if="!person_hash">
            <ACC_Contractor
                :value="contractor_id"
                @autocompleteChange="ownerChange"
                color="grey"
                :select_type="'elements'"
            />
          </v-col>
          <v-col cols="12" md="12" v-if="person_hash">
            <v-text-field type="text" filled label="Контрагент" v-model="contractor_name"
                          hide-details disabled
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Прізвище" v-model="first_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="first_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Ім’я" v-model="middle_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="middle_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="По батькові" v-model="last_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="last_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Представлення" v-model="represent_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="represent_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1"
             :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS,
  UPDATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS,
  REMOVE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'contractor_contact_person_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_ContractorContactPerson",
  mixins: [ModalComponentMixin],
  components: {
    ACC_Contractor: () => import("@/components/accounting/autocomplite/modal/ACC_Contractor")
  },
  data() {
    return {
      contractor_name: this.item.contractor_name || null,
      person_hash: this.item.person_hash || null,
      first_name: this.item.first_name,
      middle_name: this.item.middle_name,
      last_name: this.item.last_name,
      represent_name: this.item.represent_name,
      code: this.item.code || '',
      contractor_id: this.item.contractor_id || null,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.contractor_name = this.item.contractor_name || null
      this.first_name = this.item.first_name
      this.middle_name = this.item.middle_name
      this.last_name = this.item.last_name
      this.represent_name = this.item.represent_name
      this.person_hash = this.item.person_hash || null
      this.code = this.item.code
      this.contractor_id = this.item.contractor_id || null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення контактної особи контрагента (${this.short_name})`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    ownerChange(payload) {
      this.contractor_id = (payload || {})?.value || null
    },
    crud_item() {
      let error_text = `Усі поля відмічені червоним - мають бути заповнені`
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: error_text,
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        first_name: this.first_name,
        middle_name: this.middle_name,
        last_name: this.last_name,
        represent_name: this.represent_name,
        contractor_id: this.contractor_id || null,
        person_hash: this.person_hash || null,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.contractor_name = payload.contractor_name || null
              this.person_hash = payload.person_hash || null
              this.first_name = payload.first_name
              this.middle_name = payload.middle_name
              this.last_name = payload.last_name
              this.represent_name = payload.represent_name
              this.contractor_id = payload.contractor_id || null
              this.code = payload.code
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
